import React from "react";
import Layout from "../../components/Layout";
import PageHeader from "../../components/PageHeader";
import Conditions from "../../components/Conditions";
import ServicesHeader from "../../components/ServicesHeader";
import DepressionAnxietyInfo from "../../components/services/DepressionAnxietyInfo";

const DepressionAnxiety = () => {
  return (
    <Layout>
      <PageHeader text="Depression & Anxiety" />
      <ServicesHeader>
        Without treatment, depression and anxiety can become such serious
        problems that they interfere with every aspects of your life. They can
        limit your ability to work, cause relationship and family problems, and
        overwhelm you to the point it’s hard to function each day. Fortunately,
        with expert help from the board-certified physicians at Sunstate Medical
        Associates in Lake Mary, Florida, you can recover from depression and
        anxiety. Find out how to restore peace and happiness to your life by
        calling Sunstate Medical Associates today or book an appointment using
        the convenient form on this website.
      </ServicesHeader>
      <DepressionAnxietyInfo />
      <Conditions />
    </Layout>
  );
};

export default DepressionAnxiety;
