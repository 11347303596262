import React from "react";
import RequestButton from "../RequestButton";

const DepressionAnxietyInfo = () => {
  return (
    <div className="w-full flex flex-col justify-center items-center bg-white">
      <div className="w-4/5 lg:w-1/2 flex flex-col justify-start items-start py-12">
        <p className="text-3xl font-thin text-gray-800 mb-6">
          Depression/Anxiety Q & A
        </p>

        <p className="text-xl text-gray-800 mb-2">
          What are depression and anxiety?
        </p>
        <p className="text-md text-gray-600 mb-2">
          Depression and anxiety are common types of mental health problems.
          They frequently occur together and often have the same triggers. The
          Sunstate Medical Associates team often sees patients with these
          conditions and has considerable experience in helping patients
          recover.
        </p>
        <p className="text-md text-gray-600 mb-2">
          The causes of depression and anxiety might be rooted in an experience
          like bereavement, job loss, relationship breakup, or any traumatic
          life event. Sometimes even positive experiences can lead to depression
          and anxiety, like having a baby or training for a new career. In other
          cases, there might not be any obvious reason why you’re experiencing
          depression and anxiety.
        </p>
        <p className="text-md text-gray-600 mb-2">
          There are chemicals in your brain called neurotransmitters that play a
          key role in mood. If you lack neurotransmitters like serotonin, it can
          lower your mood and bring on depression. With anxiety, high levels of
          cortisol, a hormone made in your adrenal glands, can make you feel on
          edge all the time.
        </p>
        <p className="text-md text-gray-600 mb-12">
          While the causes of depression and anxiety may be similar, their
          symptoms differ.
        </p>

        <p className="text-xl text-gray-800 mb-2">
          What are the symptoms of depression and anxiety?
        </p>
        <p className="text-md text-gray-600 mb-2">
          Depression causes you to feel low all or most of the time. It’s
          natural to have changing moods, feeling down sometimes and happy at
          others. What isn’t normal is for those times when you’re feeling down
          all of the time. When you have depression, you might feel all sorts of
          negative emotions, such as:
        </p>
        <ul className="text-gray-600 text-md list-inside list-disc pl-4 mb-2">
          <li>Sadness</li>
          <li>Misery</li>
          <li>Self-loathing</li>
          <li>Guilt</li>
          <li>Shame</li>
          <li>Despair</li>
        </ul>
        <p className="text-md text-gray-600 mb-2">
          Depression takes all the positivity, optimism, and happiness out of
          your life. Every day becomes a struggle, and it might seem like
          there’s little worth living for.
        </p>
        <p className="text-md text-gray-600 mb-12">
          Anxiety is another perfectly normal human emotion everyone feels at
          times, but if you’re always anxious or fearful, you could have an
          anxiety disorder. You might have anxiety that doesn’t focus on
          anything in particular, which is called a generalized anxiety
          disorder. Or you might have anxiety that focuses on something
          specific, which is the case with social anxiety and phobias.
        </p>

        <p className="text-xl text-gray-800 mb-2">
          How are depression and anxiety treated?
        </p>
        <p className="text-md text-gray-600 mb-3">
          Treatments for depression and anxiety use two approaches:
        </p>
        <p className="text-lg text-gray-700 mb-2">Therapy</p>
        <p className="text-md text-gray-600 mb-3">
          Talk therapies might be all you need for mild depression and anxiety.
          For moderate to severe conditions, therapy combined with medication is
          usually the most effective approach. If you need therapy or
          psychiatric treatment, your provider at Sunstate Medical Associates
          can refer you to the right person.
        </p>
        <p className="text-lg text-gray-700 mb-2">Medication</p>
        <p className="text-md text-gray-600 mb-3">
          Antidepressant and anti-anxiety medications can be highly effective in
          rebalancing the neurotransmitters in your brain. There are many types
          of medication for depression and anxiety, so it can sometimes take a
          while to find the right treatment, but the right medication can be
          highly beneficial.
        </p>
        <p className="text-md text-gray-600 mb-10">
          The Sunstate Medical Associates team provides compassionate care for
          anyone experiencing depression and anxiety. Call the team today to
          speak with a friendly team member or book an appointment online.
        </p>

        <RequestButton />
      </div>
    </div>
  );
};

export default DepressionAnxietyInfo;
